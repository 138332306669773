/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';

export const DivFlex = styled.div<any>`
  display: flex;
	flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  width: ${({ width }) => width};
  height: ${({ height }) => height};
  min-height: ${({ minHeight }) => minHeight};
  max-width: ${({ maxWidth }) => maxWidth};
  margin: ${({ margin }) => margin};
  flex-wrap: ${({ wrap }) => wrap};
  position:  ${({ position }) => position};
  cursor:  ${({ cursor }) => cursor || 'default'};
  background: ${({ bgColor }) => bgColor || 'initial'};
  min-height: ${({ minHeight }) => minHeight || 'initial'};
`;

export const bold = css`
  font-family: var(--font-family-sans);
  font-weight: bold;
`;

export const semibold = css`
font-family: var(--font-family-sans);
font-weight: 600;
`;

export const flexRow = css`
  display: flex;
`;

export const flexWrap = css`
  display: flex;
  flex-wrap: wrap;
`;

export const pointer = css`
  cursor: pointer;
`;

export const relative = css`
  position: relative;
`;

export const disabled = css`
  cursor: not-allowed;
`;

export const Modal = styled.div`
  display: flex;
  background-color: white;
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
`;

export const loader = css`
width: 10vw;
margin: auto;
`;

export const capitalize = css`
  text-transform: capitalize;
`;

export const underlineHover = css`
  &:hover {
    text-decoration: underline;
  }
`;
