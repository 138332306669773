import getDateWithoutHours from './getDateWithoutHours';

export const formatListByContentType = (list) => !list?.error && list?.reduce((obj, value) => {
  const key = ['PODCAST', 'VIDEO'].includes(value.contentType) ? value.tags?.[0]?.name : value.contentType;
  const tmp = { ...obj };
  if (!tmp[key]) tmp[key] = [];
  tmp[key].push(value);
  if (['PODCAST', 'VIDEO'].includes(value.contentType)) {
    tmp[key].sort((a, b) => b.publicationDate.localeCompare(a.publicationDate));
  }
  return tmp;
}, []);

export const formatListByFirstTheme = (list) => !list?.error && list?.reduce((obj, value) => {
  const key = value.themes?.[0]?.name;
  const tmp = { ...obj };
  if (!key) return tmp;
  if (!tmp[key]) tmp[key] = [];
  tmp[key].push(value);
  return tmp;
}, []);

export const filterListMoment = (data, filters, today) => (
  !filters
  || (filters?.length === 1 && filters[0].id === 0)
    ? data && !data.error && data?.filter((el) => {
      let hasNextSession = false;
      el?.vendor?.zoom?.forEach((session) => {
        const startedDate = new Date(session.startsAt).getTime();
        if (startedDate - today > 0) {
          hasNextSession = true;
        }
      });
      return hasNextSession;
    })
    : data?.filter((el) => {
      let hasNextSession = false;
      el?.vendor?.zoom?.forEach((session) => {
        const startedDate = new Date(session.startsAt).getTime();
        if (startedDate - today > 0) {
          hasNextSession = true;
        }
      });
      return hasNextSession;
    })
      ?.filter((el) => el.themes.filter((t) => filters?.find((e) => e.id === t.id)).length));

export const filterListLive = (data, filters, today) => {
  const todayWithoutHours = getDateWithoutHours(today);
  return (
    !filters
        || (filters?.length === 1 && filters[0].id === 0)
      ? data && data?.filter((el) => {
        let hasNextSession = false;
        el?.vendor?.zoom?.forEach((session) => {
          const startedDate = getDateWithoutHours(session.startsAt);
          if (startedDate === todayWithoutHours) {
            hasNextSession = true;
          }
        });
        return hasNextSession;
      })
      : data?.filter((el) => {
        let hasNextSession = false;
        el?.vendor?.zoom?.forEach((session) => {
          const startedDate = getDateWithoutHours(session.startsAt);
          if (startedDate === todayWithoutHours) {
            hasNextSession = true;
          }
        });
        return hasNextSession;
      })
        ?.filter((el) => el.themes.filter((t) => filters?.find((e) => e.id === t.id)).length));
};
