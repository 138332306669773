const formattingDate = (date) => {
  if (!date) return '';
  const newDate = new Date(date);

  const today = new Date();
  if (newDate.getFullYear() === today.getFullYear()
    && newDate.getMonth() === today.getMonth()) {
    if (newDate.getDate() === today.getDate()) return "Aujourd'hui";
    if (newDate.getDate() === today.getDate() - 1) return 'Hier';
    if (newDate.getDate() === today.getDate() - 2) return 'Il y a deux jours';
  }

  const options:any = { year: 'numeric', month: 'long', day: 'numeric' };
  return newDate.toLocaleDateString('fr-FR', options);
};

export default formattingDate;
