import SC from './style';

const plurialize = (value, number) => (number > 1 ? `${value}s` : value);

const minutesAsReadableDuration = (minutes: number) => {
  const hours = minutes / 60;
  const modulo = minutes % 60;
  if (hours < 1) {
    return (
      <>
        <span css={SC.bulleValue}>
          {minutes}
        </span>
        <span css={SC.bulleInfo}>
          {plurialize('minute', minutes)}
        </span>
      </>
    );
  }
  if (modulo === 0) {
    return (
      <>
        <span css={SC.bulleValue}>
          {hours}
        </span>
        <span css={SC.bulleInfo}>
          {plurialize('heure', hours)}
        </span>
      </>
    );
  }
  if (hours >= 1) {
    return (
      <>
        <span css={SC.bulleValue}>
          {Math.trunc(hours)}
        </span>
        <span css={SC.bulleInfo}>
          {plurialize('heure', hours)}
        </span>
        <span css={SC.minutes}>
          {`${modulo} min`}
        </span>
      </>
    );
  } return '';
};

export default minutesAsReadableDuration;
