import { memo } from 'react';
import Image from 'next/image';
import ImgPrmm from '../../../public/prmm.svg';
import ImgTraining from '../../../public/formationBanner2.svg';
import ImgTrainingBacker from '../../../public/formationBanner1.svg';
import { text2 } from 'components/Texts/style';
import formattingDate from 'utils/formattingDate';
import { getPermanentUrl } from 'utils/url';
import SC from './style';
import { SvgOfferteCard } from 'components/Svgs';
import { capitalize } from 'styles/common';
import { isUserSubscribed } from 'utils/auth';
import InterviewImage from '../../../public/vignette-interview.svg';
import MemoirImage from '../../../public/vignette-memoir.png';
import TrainingImage from '../../../public/trainingDefault.png';
import { IContentItem } from 'domain/content';
import replaceSpaceByNbsp from 'utils/replaceSpaceByNbsp';
import getDaysLeft from 'utils/getDaysLeft';
import displayAuthors from 'utils/displayAuthors';
import useDatas from 'hooks/useDatas';
import minutesAsReadableDuration from 'components/InfosBulleTraining/minutesAsReadableDuration';

export interface CardProps extends IContentItem {
  user?: any
  category?: string
  currentPodcast?: any
  vendor?: any
  onClick?: () => void
}

const labelsCategories = {
  ARTICLE: 'Article',
  LITERATURE: 'Littérature',
  MEMOIR: 'Mémoire',
  POST: 'Billet',
  INTERVIEW: 'Interview',
  PODCAST: 'Podcast',
  VIDEO: 'Émission vidéo',
  DEBOECK: 'DeBoeck',
};

const HoverBacker = ({
  backer, replayText, duration,
}) => (
  <div className="hover-card">
    <div className="hover-backer-bg" />
    <div className="hover-backer" css={SC.hoverBacker}>
      <div css={SC.hoverPrices}>
        <div css={SC.hoverPrice}>
          <p css={SC.nextSessionTxtHover}>
            {replayText}
          </p>
          <p css={SC.hoverPriceTxt}>
            {minutesAsReadableDuration(duration)}
          </p>

        </div>
      </div>
    </div>
    {backer?.length ? (
      <SC.BackerTextHover>
        <div css={SC.baughtHoverBacker1}>
          <p css={SC.backerInfosTxt1}>*possibilité de prise en charge</p>
          {backer?.map((name) => <span key={name} css={SC.backerInfosName1}>{name}</span>)}
        </div>
      </SC.BackerTextHover>
    ) : null}
  </div>
);

const HoverFreeTraining = ({
  duration, isAccessibleForSubOnly,
}) => (
  <div className="hover-card">
    <div className="hover-backer-bg" />
    <div className="hover-backer" css={SC.hoverBacker}>
      <p css={SC.freeTraining}>
        <span>
          Formation gratuite
          {' '}
          <br />
          {' '}
          pour les abonnés
        </span>
      </p>
      <p css={[SC.hoverPriceTxt, isAccessibleForSubOnly && SC.marginHoverDuration]}>
        {minutesAsReadableDuration(duration)}
      </p>
    </div>
  </div>
);

const HoverBaughtTraining = ({ numbersDaysLeft }) => (
  <div className="hover-card">
    <div className="hover-backer-bg" />
    <div className="hover-backer" css={SC.hoverBacker}>
      {(() => {
        if (numbersDaysLeft > 0) {
          return (
            <div css={SC.numbersDaysLeft}>
              {numbersDaysLeft }
              {' '}
              jours
              <br />
              <span>restants</span>
            </div>
          );
        }
        if (numbersDaysLeft < 0) {
          return (
            <div css={SC.numbersDaysLeft0}>
              <p>
                Temps écoulé.
              </p>
              <p>Pour toute question, contactez-nous.</p>
            </div>
          );
        }
        return null;
      })()}
    </div>
  </div>
);

const hoverColors = (pricing, backerLen, isSubscriber) => {
  if (pricing === 'FREE_FOR_SUSCRIBERS' && isSubscriber) return 'rgb(188, 166, 92, 0.94)';
  if (backerLen) return 'rgb(7, 170, 219, 0.94)';
  if ((pricing === 'PAID' && !backerLen) || pricing === 'FREE_FOR_SUSCRIBERS') return 'rgb(23, 93, 249, 0.94)';
  return 'transparent';
};

const Card: React.FC<CardProps> = ({
  title, image, authors, coaches, publicationDate,
  contentType, premium, onClick, type, displayType, pricing, backer, user,
  price, vendor, programCreationDate, maxCompleteDays, doubleMaxTime, programVendor, author,
  memoirAuthor, currentPodcast, id, metadata, vendorType, duration,
}) => {
  const formattedDate = formattingDate(publicationDate);
  const isSubscriber = isUserSubscribed(user);

  // const diffDate = new Date(vendor?.zoom?.[0]?.startsAt)?.getDate() - new Date(Date.now()).getDate();
  // const daysBeforeStart = diffDate > 0 ? diffDate : 0;
  const numbersDaysLeft = getDaysLeft(programCreationDate, doubleMaxTime, maxCompleteDays);

  const { baughtTraining } = useDatas();

  const defaultImage = ({
    INTERVIEW: InterviewImage,
    MEMOIR: MemoirImage,
  });

  const getArticleImage = () => {
    if (contentType === 'INTERVIEW') return defaultImage[contentType];
    if (contentType === 'MEMOIR') return defaultImage[contentType];
    if (image) return getPermanentUrl(image, '-286x146');
    return defaultImage[contentType];
  };

  const getAuthorTexte = () => {
    const authorsTxt = displayAuthors(authors);
    if (['PODCAST', 'VIDEO'].includes(contentType)) {
      return `${author || authorsTxt} ${metadata?.guests && `& ${metadata?.guests}`}`;
    }
    return author || authorsTxt || memoirAuthor;
  };

  const isBuyed = !contentType && baughtTraining?.find((b) => b?.id === id) === undefined;

  const isAccessibleForSubOnly = pricing === 'FREE_FOR_SUSCRIBERS';

  return (
    <SC.Presentation
      bgHover={hoverColors(pricing, backer?.length, isSubscriber)}
      role="presentation"
      onClick={onClick}
      onKeyPress={onClick}
    >
      <article itemScope itemType="https://schema.org/Article" css={SC.article}>

        <SC.ProgressBar visible={programVendor?.id} percent={programVendor?.progress}>
          <div id="bar" />
        </SC.ProgressBar>

        <div css={SC.onlyFormation}>
          {(premium || isAccessibleForSubOnly) && (
          <div css={SC.premium}>
            <Image src={ImgPrmm} alt="premium content" fill sizes="100vw" />
          </div>
          )}

          {!programVendor?.id && (pricing === 'FREE_FOR_SUSCRIBERS' && isSubscriber) && !isAccessibleForSubOnly && (
          <div css={SC.subscriber}>
            <SvgOfferteCard color={(isSubscriber || pricing === 'FREE_FOR_SUSCRIBERS') && 'rgb(188, 166, 92)'} />
          </div>
          )}
          {!programVendor?.id && ((pricing === 'PAID' && !backer?.length)
          || (pricing === 'FREE_FOR_SUSCRIBERS' && !isSubscriber && !backer?.length)) && !isAccessibleForSubOnly && (
          <div css={SC.premium}>
            <Image src={ImgTraining} alt="free for subscriber" fill sizes="100vw" />
          </div>
          )}
          {!programVendor?.id && ((pricing === 'PAID' && backer?.length)
          || (!isSubscriber && pricing === 'FREE_FOR_SUSCRIBERS' && backer?.length)) ? (
            <div css={SC.premium}>
              <Image src={ImgTrainingBacker} alt="image free subscriber" fill sizes="100vw" />
            </div>
            ) : null}

          <div css={[SC.divThumbnail, !programVendor?.id && SC.borderRadius]}>

            {currentPodcast === id && (
            <div css={SC.currentPodcast}>
              <span>
                En cours
              </span>
            </div>
            )}

            {programVendor?.id && (
            <div css={SC.baughtFading}>
              {programVendor?.hasStarted ? (
                <p css={SC.progressPercent}>
                  {programVendor?.progress}
                  %
                </p>
              ) : (
                <p css={SC.startTraining}>Continuez votre formation !</p>
              )}
            </div>
            )}

            {(() => {
              if (programCreationDate && numbersDaysLeft) {
                return (
                  <HoverBaughtTraining
                    numbersDaysLeft={numbersDaysLeft}
                  />
                );
              }

              if (!programCreationDate && (pricing === 'PAID' || (!isSubscriber && pricing === 'FREE_FOR_SUSCRIBERS'))) {
                return (
                  <HoverBacker
                    replayText=""
                    backer={backer}
                    duration={duration}
                  />
                );
              }

              if (!programCreationDate && (pricing === 'FREE_FOR_SUSCRIBERS' && isSubscriber)) {
                return (
                  <HoverFreeTraining
                    duration={duration}
                    isAccessibleForSubOnly={isAccessibleForSubOnly}
                  />
                );
              }
              return null;
            })()}

            {contentType
              ? (
                <Image
                  itemProp="image"
                  placeholder="blur"
                  blurDataURL="/trainingDefaultBlur.jpg"
                  css={[SC.thumbnail, SC.zoom]}
                  src={getArticleImage()}
                  alt="thumbnail"
                  fill
                  sizes="100vw"
                  style={{
                    objectFit: 'cover',
                  }}
                />
              )
              : (
                <Image
                  placeholder="blur"
                  itemProp="image"
                  blurDataURL="/trainingDefaultBlur.jpg"
                  css={[SC.thumbnail]}
                  src={image ? getPermanentUrl(image, '-286x146') : TrainingImage}
                  alt="thumbnail"
                  fill
                  sizes="100vw"
                  style={{
                    objectFit: 'cover',
                  }}
                />
              )}

            {contentType
              ? (
                <SC.H4Category>
                  {labelsCategories[contentType]}
                </SC.H4Category>
              )
              : (
                <SC.H4Category category="training">
                  {displayType || labelsCategories[type]}
                </SC.H4Category>
              )}
          </div>

          <div css={[SC.content, SC.customPadding]}>
            {contentType ? (
              <h3
                itemScope
                itemType="https://schema.org/Person"
                itemProp="author"
                css={[text2, capitalize]}
              >
                <span itemProp="name">{getAuthorTexte()}</span>
              </h3>
            ) : (
              <h3
                itemScope
                itemType="https://schema.org/Person"
                itemProp="author"
                css={[text2, capitalize]}
              >
                <span itemProp="name">
                  {coaches?.map(({ fullName }, i) => (i === coaches.length - 1 ? fullName?.toLowerCase() : `${fullName?.toLowerCase()}, `))}
                </span>
              </h3>
            )}
            {contentType
              ? <time itemProp="datePublished" dateTime={publicationDate} css={SC.date}>{formattedDate}</time>
              : (
                <span css={SC.date}>{vendorType === 'EGNOSIA' ? 'E-gnosia Formations' : ''}</span>
                // <span css={SC.date} />
              )}
            <h2 itemProp="headline" css={SC.title}>{replaceSpaceByNbsp(title)}</h2>
          </div>

          {isBuyed && (
            <div css={SC.bottomCardInfo} style={{ color: 'black' }}>
              <span css={SC.otherLitlePriceB} style={{ color: 'black' }}>
                {pricing === 'FREE_FOR_SUSCRIBERS' ? (
                  <b>
                    Gratuit pour les abonnés
                  </b>
                ) : (
                  <b>
                    {price?.standard}
                    €
                  </b>
                )}
              </span>
            </div>
          )}
        </div>

      </article>
    </SC.Presentation>
  );
};

export default memo(Card);
