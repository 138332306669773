import styled, { css } from 'styled-components';

const bulleValue = css`
  font-size: var(--size-25);
  font-family: var(--font-family-sans);
  font-weight: 600;
  line-height: var(--size-25);
`;

const bulleOffer = css`
  font-size: var(--size-15);
  font-family: var(--font-family-sans);
  font-weight: 600;
  line-height: 3.33vh;
`;

const bulleInfo = css`
  font-size: var(--size-10);
  font-family: var(--font-family-sans);
  font-weight: 600;
  word-break: break-word;
  text-align: center;
  text-transform: uppercase;
  line-height: var(--size-15);
  width: 75%;
`;

const bulle = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 53px;
  min-height: 53px;
  width: 3.8vw;
  height: 3.8vw;
  background-color: #BFBFBF84;
  border-radius: 50%;
  font-size: var(--size-28);
  color: #FFFFFF;
  margin: 1.85vh 0.35vw 0 0;
`;

const bulleDurationColor = css`
  background-color: #BFBFBF84;
  color: var(--color-black-02);
`;

const bulleStandardSubscribed = css`
  background-color: transparent;
  color: var(--color-black-02);
`;

const bullePriceColor = css`
  background-color: var(--color-deep-sky-blue);
  color: #FFFFFF;
`;

const bullePriceSuscriber = css`
  background-color: #D9BB53;
  color: #FFFFFF;

  &:hover {
    #cta-subscription {
      visibility: visible;
      transition: visibility 0s ease-out 0s;
    }
  }
`;

const bulleNewOffer = css`
background-color: white;
`;

const bullePriceStandard = css`
  background-color: #f917d6;
  color: #FFFFFF;
`;

const lineThrough = css`
  text-decoration: line-through;
  font-family: var(--font-family-sans);
`;

const TxtAboveNbr = styled.p<any>`
  font-family: var(--font-family-sans);
  font-weight: ${({ regular }) => (regular ? '400' : '600')}; 
  font-size: var(--size-10);
  color: ${({ white }) => (white ? '#FFFFFF' : 'var(--color-black-02)')};
  color: ${({ subscribed }) => (subscribed && 'var(--color-gray)')};
  color: ${({ color }) => (color || 'inherit')};
  line-height: 1;
  text-align: center;
  visibility: ${({ invisible }) => (invisible ? 'hidden' : 'visible')};
  min-height: var(--size-10);
`;

const dispo = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-10);
  color: var(--color-black-02);
  line-height: 1;
`;

const dispoDays = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-10);
  color: var(--color-black-02);
  line-height: 1;
`;

const nbDays = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-28);
  color: var(--color-black-02);
  line-height: 0.8;
`;

const ctaSubTxt = css`
  font-family: var(--font-family-sans);
  font-size: var(--size-18);
  line-height: 1.44;
  color: white;
  width: 11vw;
  b {
    font-family: var(--font-family-sans);
    font-weight: bold;
  }
  
`;
const ctaSubBtn = css`
  height: 5.5vh;
  margin-top: 2vh;
  background-color: var(--color-gold);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;

  p {
  font-family: var(--font-family-sans);
  font-weight: 600;
    font-size: var(--size-18);
    line-height: 1.22;
    text-transform: uppercase;
  }
`;

const ctaSubBtnSub = css`
  height: 5.5vh;
  margin-top: 2vh;
  background-color: transparent;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  cursor: default;
  padding: 0;

  p {
    font-family: var(--font-family-sans);
    font-style: italic;
    font-size: var(--size-16);
    line-height: 1.22;
  }
`;

const ctaSubscription = css`
  visibility: hidden;
  position: absolute;
  background-color: rgb(26 26 26 / 91%);
  width: min-content;
  left: 30vw;
  z-index: 1000;
  padding: 1.48vh 1.3vw;
  border-radius: 15px;

  transition: visibility 0.2s ease-out 0s;

  &:after {
    content: ' ';
    width: 0;
    height: 0px;
    border-top: 1.3888vh solid transparent;
    border-left: 1.0416vw solid rgb(26 26 26 / 91%);
    border-bottom: 1.3888vh solid transparent;
    border-right: 0 solid transparent;
    right: 100%;
    position: absolute;
    top: 44%;
    transform: rotate(180deg);
  }
`;

const minutes = css`
  font-size: var(--size-14);
  line-height: 1;
  font-family: var(--font-family-sans);
  font-weight: 600;
`;

const bulleColorOffer = css`
  color: #F91CD6;
`;

export default {
  bulle,
  bulleDurationColor,
  bulleColorOffer,
  bullePriceColor,
  bulleStandardSubscribed,
  bullePriceSuscriber,
  bulleValue,
  bulleOffer,
  bulleInfo,
  bullePriceStandard,
  TxtAboveNbr,
  dispo,
  dispoDays,
  nbDays,
  ctaSubTxt,
  ctaSubBtn,
  ctaSubBtnSub,
  ctaSubscription,
  minutes,
  lineThrough,
  bulleNewOffer,
};
