import styled, { css } from 'styled-components';

const divThumbnail = css`
  height: 13.5185vh; // 146px;
  max-height: 146px; // 146px;
  margin-bottom: var(--height-xxs);
  position: relative;
  overflow: hidden;
`;

const borderRadius = css`
  border-radius: 25px 25px 0 0;
`;

const Presentation = styled.div<any>`
  cursor: pointer;
  margin-top: 0.7407407407407407vh;

  .hover-backer-bg {
    display: flex;
    position: absolute;
    width: 0vw;
    height: 0vw;
    border-radius: 50%;
    z-index: 5;
    margin: auto;
    top: 0;
    bottom: 0;
    left: -1vw;
    right: 0;
    transition: all 0.5s;
    /* margin-left: -1vw; */
  }

  .hover-backer {
      opacity: 0;
  }

  &:hover {
    .hover-backer-bg {
      display: flex;
      transition: all 0.5s;
      background-color: ${({ bgHover }) => bgHover};
      width: 20vw;
      height: 20vw;
      z-index: 30;
    }
    .hover-backer {
      opacity: 1;
    }
  }
`;

const thumbnail = css`
  object-fit: cover; 
`;

const zoom = css`
  ${Presentation}:hover & {
    transform: translateZ(0) scale(1.05);
  }
  transform: translateZ(0) scale(1.0, 1.0);
  transition: transform 0.5s;
`;

const content = css`
  display: flex;
  height: 22vh;
  flex-direction: column;
`;

const title = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-21);
  color: var(--color-black);
  line-height: 1.29;
  margin-bottom: 0.277vh;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  line-clamp: 2;
  -webkit-box-orient: vertical;
`;

const date = css`
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-18);
  line-height: 1.06;
  color: var(--color-black);
  margin-bottom: 0.277vh;
  margin-top: 0.277vh;


  b {
    font-weight: normal;
  }
`;

const bgColors = {
  training: 'var(--color-deep-sky-blue)',
};
const colors = {
  training: 'white',
};

const H4Category = styled.h4<any>`
  font-family: var(--font-family-serif);
  font-size: var(--size-16);
  color: ${({ category }) => colors[category] || 'var(--color-black)'};
  min-height: 2.8vh;
  line-height: 1.3;
  display: flex;
  align-items: center;
  position: absolute;
  bottom: 0px;
  background-color: ${({ category }) => bgColors[category] || 'var(--color-greenish-cyan)'};
  padding: 0 var(--height-xs);
`;

const subscriber = css`
  min-width: 41px;
  width: 3.8vw;
  height: 7.13vh;
  min-height: 48px;
  position: relative;
  margin-bottom: -7.13vh;
  left: 0;
  top: 0;
  z-index: 20;
`;

const premium = css`
  width: var(--card-img-price-w);
  height: var(--card-img-price-h);
  position: relative;
  margin-bottom: calc(-1 * var(--card-img-price-h));
  top: var(--card-img-price-top);
  margin-left: var(--card-img-price-m);
  z-index: 40;
`;

const article = css`
  position: relative;
  padding: 9px 0;
`;

const hoverCard = css`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: 0.2s;
  z-index: -1;
  ${Presentation}:hover & {
    background-color: rgba(87, 81, 81, 0.22);
  }
`;

const hoverBacker = css`
  width: 100%;
  height: 100%;
  position: absolute;
  transition: 0.2s;
  z-index: 50;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0;
`;

const helpDiscount = css`
  background-color: white;
  color: var(--color-deep-sky-blue);
  padding: 0 4px;
  margin-right: 0.5vw;
  font-family: var(--font-family-sans);
  font-weight: 600;
  display: inline-flex;
  justify-content: center;
  font-size: var(--size-16);
  min-width: 2.55vw;
  
`;

const listHelp = css`
  display: none;
  ${Presentation}:hover & {
    display: flex;
  }
  position: absolute;
  bottom: 1vh;

  & > :last-child {
    margin-right: 0;
  }
`;

const hoverPrices = css`
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0 2.34vw;
  margin-top: 3vh;
`;
const hoverPrice = css`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
`;

const hoverPriceNumber = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-26);
  color: white;
  line-height: 0.74;
  margin-left: 0.5vw;
`;

const hoverPriceTxt = css`
  display: flex;
  align-items: center;
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-18);
  color: white;
  line-height: 1.33;
  text-align: center;
  flex-direction: column;
  width: 100%;
`;

const hoverPriceTxt2 = css`
  display: flex;
  align-items: center;
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-14);
  color: white;
  line-height: 1.33;
  text-align: center;
`;

const marginBot = css`
  margin-bottom: 0.5vh;
`;

const freeTraining = css`
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-18);
  color: white;
  position: absolute;
  top: 1vh;
  line-height: 1;
  text-align: center;
`;

const freeTrainingSuscriber = css`
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-18);
  color: white;
  text-align: center;
  padding: 0 1vw;
  line-height: 1.85vh;
`;

const hoverOtherPrice = css`
  position: absolute;
  bottom: 0.55vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-15-5);
  color: white;
  line-height: 1;
  z-index: 5;
  p {
    z-index: 10;
    display: inline-flex;
    align-items: center;
  }
`;

const otherPriceLitle = css`
  font-size: var(--size-14);
  line-height: 0.8;
`;

const otherLitlePriceB = css`
  line-height: 0.7;
`;

const hoverOtherAbonne = css`
  position: absolute;
  bottom: 1.1vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-12);
  color: white;
  line-height: 1;
  z-index: 5;
  p {
    z-index: 10;
    display: inline-flex;
    align-items: center;
  }
`;

const hoverOtherPriceB = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
`;

const nextSessionTxtHover = css`
  position: absolute;
  top: 1vh;
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-18);
  color: white;
  b {
    font-family: var(--font-family-sans);
    font-weight: bold;
    font-style: italic;
  }
`;

const freeNextSession = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 1vh;
`;

const freeNextSessiontxt = css`
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-14);
  color: white;
  margin-bottom: 1vh;
`;

const freeNextSessionNbr = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-38);
  color: white;
  line-height: 0.74;
`;
const backerInfos = css`
  position: absolute;
  left: var(--card-backer-left);
  top: var(--card-backer-top);
  /* width: var(--card-backer-width); */
  text-align: center;
  display: flex;
  flex-direction: column;
`;
const backerInfosTxt = css`
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-12);
  color: white;
  line-height: 1;
  /* margin-bottom: 0.5vh; */
`;

const backerInfosTxt1 = css`
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-12);
  color: white;
  line-height: 1;
  /* margin-bottom: 0.5vh; */
  margin-right: 1vw;
`;

const backerInfosName = css`
  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-12);
  color: white;
  line-height: 1.3;
`;

const backerInfosName1 = css`
  font-family: var(--font-family-sans);
  font-weight: bold;
  font-size: var(--size-12);
  color: white;
  line-height: 1.3;
  margin-right: 0.5vw;
`;

const ruban = css`
  width: 11vw;
  height: 3vh;
`;

const numbersDaysLeft = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-38);
  line-height: 0.74;
  color: white;
  text-align: center;
  margin-top: 1vh;

  span {
    font-size: var(--size-16);
  }
`;

const numbersDaysLeft0 = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-38);
  line-height: 0.74;
  color: white;
  text-align: center;
  margin-top: 1vh;

  p:last-child {
    font-size: var(--size-16);
    line-height: 1.2;
    margin: 2vh auto 0 auto;
    width: 90%;
  }

  p:first-child {
    font-size: var(--size-26);
  }
`;

const baughtHoverBacker = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 0.5vh;
`;

const baughtHoverBacker1 = css`
  display: flex;
  align-items: center;
`;

const seulement = css`
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-12);
  color: white;
`;

const progressPercent = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-21);
  color: #53f188;
  text-transform: uppercase;
  margin-top: 0.3645vw;
  margin-left: 0.3645vw;
  line-height: 1;
`;

const ProgressBar = styled.div<any>`
  width: 100%;
  height: 0.648vh;
  background-color: #f5f5f5;
  border: ${({ percent }) => (!percent ? '1px solid #c5c5c5' : 'none')};
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  border-bottom: none;
  border-left: none;

  #bar {
    width: ${({ percent }) => (percent ? `${percent}%` : 0)};
    height: 100%;
    background-color: #53f188;
  }
`;

const startTraining = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-14);
  color: #53f188;
  text-transform: uppercase;
  margin-top: 0.3645vw;
  margin-left: 0.3645vw;
  line-height: 1;

  animation: blinker 3.5s linear infinite;

  @keyframes blinker {  
    50% { opacity: 0; }
  }
`;

const baughtFading = css`
  width: 100%;
  height: 4.35vh;
  opacity: 0.76;
  background: linear-gradient(to bottom,#000,rgba(0,0,0,0));
  position: absolute;
  z-index: 20;
`;

const mbottom = css`
  margin-bottom: 1px;
`;

const currentPodcast = css`
    position: absolute;
    width: 100%;
    height: 100%;
    background: #0000005e;
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: var(--size-27);
`;

const onlyFormation = css`
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  box-shadow: 0 3px 6px 0 rgb(0 0 0 / 16%);
  background-color: #fff;
  min-height: var(--card-min-height);
`;

const bottomCardInfo = css`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 3.79vh;
  background-color: #e8e8e8;
  border-radius: 0 0 25px 25px;
  /* margin-top: auto; */
`;

const newText1Subscribe = css`
    margin: auto auto 2px;
    font-style: italic;
    color: var(--color-gold);
    line-height: 1;
    text-align: center;
    font-size: var(--size-16);
`;

const newText1 = css`
    margin: auto auto 2px;
    font-style: italic;
    text-align: left;
    color: var(--color-black);
    text-decoration: line-through;
    font-size: var(--size-16);
`;

const newText2Subscribe = css`
  /* font-style: italic; */
  text-align: left;
  color: #bca65c;
  font-size: var(--size-16);

  b {
    font-weight: bold;
    font-size: var(--size-16);
  }
`;

const newText2 = css`
    font-weight: bold;
    font-size: var(--size-16);
    color: var(--color-black);
    line-height: var(--size-16);
    text-align: center;
`;

const freeText = css`
  font-weight: 600;
  line-height: 0.46;
  text-align: center;
  color: #fff;
  font-size: var(--size-26);
  margin-bottom: 0.5vh;
`;

const freeTextSub = css`
  font-size: 1.125rem;
  font-style: italic;
  line-height: 1.33;
  text-align: center;
  color: #fff;
  font-size: var(--size-18);
`;

const customPadding = css`
 padding: 0 0.833vw;
`;

const BackerTextHover = styled.div`
${Presentation}:hover & {
  padding: 0.5vh 0.7vw 0.5vh 0.4vw;
  background-color: rgb(0 100 132 / 66%);
  height: 2.5vh;
  bottom: 0;
  width: 100%;
  z-index: 50;
  position: absolute;
}
`;

const txtStandard = css`
  font-size: var(--size-16);
  color: var(--color-black);
`;

const marginHoverDuration = css`
  margin-top: 3vh;
`;

export default {
  freeText,
  BackerTextHover,
  freeTextSub,
  marginHoverDuration,
  customPadding,
  newText1Subscribe,
  newText1,
  txtStandard,
  newText2,
  newText2Subscribe,
  ruban,
  backerInfos,
  baughtFading,
  currentPodcast,
  progressPercent,
  mbottom,
  bottomCardInfo,
  startTraining,
  ProgressBar,
  onlyFormation,
  backerInfosTxt,
  backerInfosTxt1,
  marginBot,
  baughtHoverBacker,
  baughtHoverBacker1,
  backerInfosName,
  backerInfosName1,
  seulement,
  numbersDaysLeft,
  numbersDaysLeft0,
  otherPriceLitle,
  otherLitlePriceB,
  freeNextSession,
  subscriber,
  freeNextSessiontxt,
  freeNextSessionNbr,
  freeTraining,
  freeTrainingSuscriber,
  nextSessionTxtHover,
  hoverOtherPriceB,
  hoverOtherPrice,
  hoverOtherAbonne,
  thumbnail,
  zoom,
  content,
  title,
  date,
  divThumbnail,
  H4Category,
  Presentation,
  premium,
  article,
  hoverCard,
  helpDiscount,
  listHelp,
  hoverPrices,
  hoverPrice,
  hoverPriceNumber,
  hoverPriceTxt,
  hoverPriceTxt2,
  hoverBacker,
  borderRadius,
};
