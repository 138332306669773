import { FC } from 'react';
import Link from 'next/link';
import styled from 'styled-components';
import Card from 'components/Card';
import { IContentItem } from 'domain/content';
import formatTitleAsUrl from 'utils/formatTitleAsUrl';

export interface Props extends IContentItem {
  user?: any
  status?: string
  category?: string
  autoAdjust?: any
  toggleModal?: (id: string, contentType: string) => void; // TODO: rename onClickItem
  comeSearch?: boolean
  currentPodcast?: any
}

const ItemWrapper = styled.div<any>`
  width: ${({ autoAdjust }) => (autoAdjust ? '12.2vw' : '14.8958vw')};
  max-width: 286px;
  min-width: 150px;
`;

const CardWrapper: FC<Props> = (props) => {
  const {
    user, category, id, comeSearch, contentType, autoAdjust, title, status,
  } = props;
  const goodRedirection = {
    training: 'formations',
    content: 'informations',
    'mon-academy': `mon-academy/${contentType ? 'informations' : 'formations'}`,
  };
  const idUrl = formatTitleAsUrl({ id: comeSearch ? id.split('-')[1] : id, title });
  const isPreview = status === 'DRAFT' ? '?isPreview=true' : '';
  const href = `/${goodRedirection[category]}/${idUrl}${isPreview}`;
  return (
    <ItemWrapper autoAdjust={autoAdjust}>
      <Link
        href={href}
        shallow
        passHref
      >
        <Card {...props} user={user} />
      </Link>
    </ItemWrapper>
  );
};

export default CardWrapper;
