const getProgramVendor = (card, baughtTraining) => {
  if (baughtTraining?.length && card.displayType) {
    const program = baughtTraining.find((el) => el.id === card.id);
    return {
      programCreationDate: program?.programCreationDate,
      programVendor: program?.programVendor,
    };
  }
  return {};
};

export default getProgramVendor;
