import { css } from 'styled-components';

export const text1 = css`
  font-family: var(--font-family-serif);
  font-weight: bold;
  font-size: var(--size-36);
  line-height: 1.25;
  color: var(--color-black);  
`;

export const text2 = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-18);
  line-height: 1.06;
  color: var(--color-gray);
`;

export const text3 = css`
  font-family: var(--font-family-sans);
  font-style: italic;
  font-size: var(--size-18);
  line-height: 1.3;
  color: var(--color-black); 
`;

export const text4 = css`
  font-family: var(--font-family-sans);
  font-weight: 600;
  font-size: var(--size-21);
  line-height: 0.9;
  color: var(--color-black); 
`;

export const text5 = css`
  font-family: var(--font-family-sans);
  font-weight: 300;
  font-size: var(--size-18);
  color: var(--color-gray-warm);
  line-height: 0.9;
`;
