const displayAuthors = (authors) => {
  if (!authors?.length) return null;
  const t = authors.map((a, i) => {
    if (i !== authors.length - 1) return `${a.fullName?.toLowerCase()}, `;
    return a.fullName?.toLowerCase();
  });
  return t.join('');
};

export default displayAuthors;
