const getDaysLeft = (programCreationDate, doubleMaxTime, maxCompleteDays) => {
  if (!maxCompleteDays) return null;

  const diffLeft = Date.now() - new Date(programCreationDate).getTime();
  const maxDays = doubleMaxTime ? maxCompleteDays * 2 : maxCompleteDays;
  const numbersDaysLeft = maxDays - Math.abs(Math.round(diffLeft / (1000 * 3600 * 24)));
  return numbersDaysLeft;
};

export default getDaysLeft;
