import {
  createContext, ReactNode, useEffect, useReducer,
} from 'react';
import hnaRequest from 'utils/hnaRequest';
import handleResponse from 'utils/handleAllSettledRes';
import useAuth from 'hooks/useAuth';
import { formatListByContentType, formatListByFirstTheme } from 'utils/formatListByContentType';
import { isUserSubscribed } from 'utils/auth';
import useHistory from 'hooks/useHistory';

interface DatasProps {
  events: any
  contents: any
  trainings: any
  baughtTraining: any
  selectedForYou: any
  showcaseList: any
  topContents: any
  topTrainings: any
  premiumList: any
  children: ReactNode
}

export type DatasContextType = {
  events: any
  contentList: any
  trainingList: any
  formattedContentsList: any
  formattedTrainingsList: any
  baughtTraining: any
  selectedForYou: any
  showcaseList: any
  topContents: any
  topTrainings: any
  premiumList: any
  refetch: any
  fetchTrainings: any
  fetchAcademy: any
  fetchContents: any
}

const initialContext = {
  events: [],
  contentList: {},
  formattedContentsList: {},
  formattedTrainingsList: {},
  trainingList: {},
  baughtTraining: [],
  selectedForYou: null,
  showcaseList: [],
  topContents: [],
  topTrainings: [],
  premiumList: [],
  refetch: () => {},
  fetchTrainings: () => {},
  fetchContents: () => {},
  fetchAcademy: () => {},
};

export const DatasContext = createContext<DatasContextType>(initialContext);

const reducer = (state, action) => {
  switch (action.type) {
    case 'fetchTrainings':
      return {
        ...state,
        trainingList: action.payload.tList,
        baughtTraining: action.payload.bList,
        formattedTrainingsList: action.payload.formattedTrainings,
      };
    case 'fetchContents':
      return {
        ...state,
        contentList: action.payload.cList,
        formattedContentsList: action.payload.formattedList,
      };
    case 'fetchAcademy':
      return { ...state, ...action.payload };
    default:
      break;
  }
  return null;
};

const DatasProvider = ({
  children, contents, trainings, baughtTraining,
  selectedForYou, showcaseList, topContents, topTrainings, premiumList, events,
}: DatasProps) => {
  const initialState = {
    contentList: contents,
    formattedContentsList: formatListByContentType(contents),
    trainingList: trainings,
    formattedTrainingsList: formatListByFirstTheme(trainings),
    baughtTraining,
    selectedForYou,
    showcaseList,
    topContents,
    topTrainings,
    premiumList,
    events,
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const { home } = useHistory();
  const { user } = useAuth();

  const fetchTrainings = async () => {
    const promises = [
      hnaRequest('get', 'training/list', undefined),
      user && hnaRequest('get', 'training/baught-trainings-list', undefined),
    ];
    const res = await Promise.allSettled(promises);
    const tList = handleResponse(res?.[0]);
    const bList = handleResponse(res?.[1]);
    const formattedTrainings = formatListByFirstTheme(handleResponse(res?.[0]));
    dispatch({ type: 'fetchTrainings', payload: { tList, bList, formattedTrainings } });
  };

  const fetchContents = async () => {
    const promises = [
      hnaRequest('get', 'content/list', undefined),
    ];
    const res = await Promise.allSettled(promises);
    const cList = res?.[0] !== undefined ? handleResponse(res?.[0]) : null;

    const formattedList = formatListByContentType(cList);

    dispatch({ type: 'fetchContents', payload: { cList, formattedList } });
  };

  const fetchAcademy = async () => {
    const promises = [
      !state.selectedForYou && hnaRequest('get', 'academy/selected-for-you-list', undefined),
      user && hnaRequest('get', 'training/baught-trainings-list', undefined),
      hnaRequest('get', 'training/top-trainings-list', undefined),
      hnaRequest('get', 'content/top-content-list', undefined),
      hnaRequest('get', 'content/premium-list', undefined),
    ];
    const res = await Promise.allSettled(promises);
    const selected = handleResponse(res?.[0]);
    dispatch({
      type: 'fetchAcademy',
      payload: {
        selectedForYou: state.selectedForYou
        || [...selected?.contents, ...selected?.trainings].sort(() => 0.5 - Math.random()),
        baughtTraining: handleResponse(res?.[1]),
        topTrainings: handleResponse(res?.[2]),
        topContents: handleResponse(res?.[3]),
        premiumList: handleResponse(res?.[4]),
      },
    });
  };

  const refetch = async () => {
    fetchAcademy();
    fetchTrainings();
    fetchContents();
  };

  useEffect(() => {
    if (home === 'informations' && (!state.contentList || state.contentList?.error)) fetchContents();
    if (home === 'formations') fetchTrainings(); // TO IMPROVE
    if (home === 'mon-academy' && (!state.topContents || state.topContents?.error || (isUserSubscribed(user) && !state.premiumList))) {
      fetchAcademy();
      fetchContents();
      fetchTrainings();
    }
  }, [home]);

  useEffect(() => {
    fetchTrainings();
  }, [user]);

  return (
    <DatasContext.Provider value={{
      ...state, refetch, fetchTrainings, fetchContents, fetchAcademy,
    }}
    >
      {children}
    </DatasContext.Provider>
  );
};

export default DatasProvider;
